import {Component} from "react";
import {Box, Typography} from "@mui/material";

export class Footer extends Component {
    render() {
        return (
            <Box pt={2} pb={2} sx={{
                backgroundColor: "rgba(255,255,255,0.05)",
                width: "100%",
                textAlign: "center",
            }}>
                <Typography variant={"subtitle2"}>© Arcane Studios 2023</Typography>
            </Box>
        );
    }
}
