import {useEffect, useRef, useState} from "react";
import {Box, Button, CircularProgress, Fade, Grow, Pagination, Typography} from "@mui/material";
import {Page} from "../Page";
import Grid2 from "@mui/material/Unstable_Grid2";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {apiClient} from "../../api/ApiClient";
import {ProjectModel} from "../../api/model/ProjectModel";
import {Project} from "./Project";
import {ArrowBackIos} from "@mui/icons-material";
import {Link} from "react-router-dom";

export const ProjectsPage = () => {
    let [projectsCount, setProjectsCount] = useState(1);
    let [page, setPage] = useState(1);
    let [projects, setProjects] = useState<ProjectModel[]>([]);
    const categoryRef = useRef(
        decodeURI(window.location.pathname)
            .replaceAll("%20", " ") // Temp fix for invalid displaying bug
            // .replaceAll("-", " ")
            .replaceAll("_", " ")
            .substring(1)
    )

    useEffect(() => {
        apiClient.loadProjectsCount(categoryRef.current).then(r => setProjectsCount(r))
    }, [page])
    useEffect(() => {
        setProjects([])
        apiClient.loadProjects(categoryRef.current, (page - 1) * 12, 12).then(r => setProjects(r))
    }, [page])
    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [page])

    return (
        <Box>
            <Box sx={{
                position: "absolute",
                bottom: "20px",
                left: "20px"
            }}>
                <Box to={"/"} component={Link} sx={{
                    textDecoration: "none"
                }}>
                    <Button>
                        <ArrowBackIos/>
                        <Typography>Services</Typography>
                    </Button>
                </Box>
            </Box>
            <Page>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    alignItems: "center",
                    width: "100%"
                }}>
                    <Box sx={{
                        width: "100%",
                        textAlign: "center"
                    }}>
                        <Typography mt={3} variant={"h3"}>{categoryRef.current} Projects</Typography>
                    </Box>
                    <Box mb={3} mt={3} sx={{
                        flexGrow: 1,
                        width: "100%"
                    }}>
                        <Grow timeout={500} in={projects.length > 0}>
                            <Grid2 sx={{}} wrap={"wrap"} columns={{
                                xs: 1, sm: 2, md: 3, xl: 3
                            }} direction={"row"} spacing={2} container>
                                {projects.map(function (model) {
                                    return <Grid2 key={model.id} xs={1}><Project model={model}/></Grid2>
                                })}
                            </Grid2>
                        </Grow>
                        <Fade mountOnEnter unmountOnExit timeout={0} in={projects.length === 0}>
                            <Grid2 xs={1}>
                                <Box sx={{
                                    height: "368px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyItems: "center",
                                    justifyContent: "center",
                                    alignContent: "center"
                                }}>
                                    <CircularProgress/>
                                </Box>
                            </Grid2>
                        </Fade>
                    </Box>
                    <Pagination page={page} onChange={(event, page) => setPage(page)} shape={"rounded"}
                                variant={"outlined"} count={Math.ceil(projectsCount / 12)}/>
                </Box>
            </Page>
        </Box>
    )
}
