import {PortfolioModel} from "./model/PortfolioModel";
import {ProjectModel} from "./model/ProjectModel";

export class ApiClient {

    private host = "https://archie.arcanestudios.co/api/"

    async loadProjectsCount(profession: string): Promise<number> {
        return fetch(this.host + "projects/list/" + profession + "/count").then(function (response) {
            return response.json().then((json) => {
                return json["count"] as number
            })
        })
    }

    async loadProjects(profession: string, offset: number, limit: number): Promise<Array<ProjectModel>> {
        return fetch(this.host + "projects/list/" + profession + "?offset=" + offset + "&limit=" + limit).then(function (response) {
            return response.json().then(function (json) {
                let result: Array<ProjectModel> = Object.assign(new Array<ProjectModel>(), json)
                return result.sort((a, b) => {
                    if (a.timestamp > b.timestamp) {
                        return -1
                    }
                    if (a.timestamp < b.timestamp) {
                        return 1
                    }
                    return 0
                })
            })
        })
    }

    async loadCategories(): Promise<Array<PortfolioModel>> {
        return fetch(this.host + "projects/categories").then(function (response) {
            return response.json().then(function (json) {
                let result: Array<PortfolioModel> = Object.assign([], json);
                return result.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })
            })
        })
    }
}

export let apiClient = new ApiClient()