import {PropsWithChildren, useEffect} from "react";
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import {HomePage} from "../pages/home/HomePage";
import {Box, createTheme, CssBaseline, Divider, ThemeProvider} from "@mui/material";
import {Header} from "../header/Header";
import {Footer} from "../footer/Footer";
import {ProjectsPage} from "../pages/project/ProjectsPage";
import {TransitionGroup} from "react-transition-group";

const theme = createTheme(
    {
        palette: {
            mode: 'dark',
            primary: {
                main: "#18AE6E",
                contrastText: "#000"
            },
            background: {
                default: "#1E1E1E"
            }
        },
        typography: {
            fontFamily: "'Roboto Condensed', serif"
        }
    }
)

export function Application() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <BrowserRouter>
                <Header/>
                <Divider light/>
                <ScrollToTop>
                    <Box mb={3} component={TransitionGroup} sx={{
                        flexGrow: 1,
                    }}>
                        <Routes>
                            <Route path={"/"} element={<HomePage/>}/>
                            <Route path={"/*"} element={<ProjectsPage/>}/>
                        </Routes>
                    </Box>
                </ScrollToTop>
                <Box sx={{ height: "70px" }}/>
                <Footer/>
            </BrowserRouter>
        </ThemeProvider>
    );
}

function ScrollToTop(props: PropsWithChildren) {
    const {pathname} = useLocation();
    console.log(pathname)
    useEffect(() => {
        document.body.scrollTo(0, 0);
    }, [pathname]);
    return <>{props.children}</>;
}
