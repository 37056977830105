import React, {Component} from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    IconButton,
    Modal,
    Slide,
    SvgIcon,
    Typography
} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import {ProjectModel} from "../../api/model/ProjectModel";


export class Project extends Component<{
    model: ProjectModel
}, {
    detailsView: boolean,
}> {

    constructor(props: { model: ProjectModel }) {
        super(props);
        this.state = {
            detailsView: false,
        }
    }

    private formattedTimestamp(): string {
        let date = new Date(this.props.model.timestamp * 1000)
        return date.toLocaleDateString()
    }

    private isVideo(url: string): boolean {
        return url.endsWith(".mp4") || url.endsWith(".mov") || url.endsWith(".webm")
    }

    private description(): string {
        let description = this.props.model.description
        if (description.length === 0) {
            return "This project has no description at this moment."
        } else {
            return description
        }
    }


    private detailsModal() {
        let thisRef = this
        return <Modal open={this.state.detailsView} onClose={function () {
            thisRef.setState({
                detailsView: false
            })
        }}>
            <Slide direction={"up"} in={this.state.detailsView}>
                <Box className={"modal_parent"} onClick={function (event) {
                    let parent = document.getElementsByClassName("modal_parent").item(0)
                    if (parent !== event.target) return
                    thisRef.setState({
                        detailsView: false
                    })
                }} sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                }}>
                    <Card sx={{
                        maxWidth: {
                            xs: "95%",
                            lg: "750px"
                        },
                        border: "solid 3px",
                        borderColor: "primary.dark",
                        borderRadius: "12px",
                        boxShadow: 24,
                        boxSizing: "border-box",
                        overflowY: "auto",
                    }}>
                        <Box pt={1} pb={1} sx={{
                            width: "100%",
                            backgroundColor: "#272727",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <Typography ml={2} mr={3} variant={"h5"} sx={{
                                flexGrow: 1
                            }}>{this.props.model.name}</Typography>
                            <IconButton onClick={function () {
                                thisRef.setState({
                                    detailsView: false
                                })
                            }} sx={{
                                marginRight: "10px"
                            }}>
                                <SvgIcon viewBox="0 0 24 24" fill="none" height={"16px"} width={"16px"}>
                                    <path
                                        d="M3.35288 8.95043C4.00437 6.17301 6.17301 4.00437 8.95043 3.35288C10.9563 2.88237 13.0437 2.88237 15.0496 3.35288C17.827 4.00437 19.9956 6.17301 20.6471 8.95044C21.1176 10.9563 21.1176 13.0437 20.6471 15.0496C19.9956 17.827 17.827 19.9956 15.0496 20.6471C13.0437 21.1176 10.9563 21.1176 8.95044 20.6471C6.17301 19.9956 4.00437 17.827 3.35288 15.0496C2.88237 13.0437 2.88237 10.9563 3.35288 8.95043Z"
                                        stroke="#fff" fill={"transparent"} strokeWidth="1.5"/>
                                    <path d="M13.7678 10.2322L10.2322 13.7677M13.7678 13.7677L10.2322 10.2322"
                                          fill={"transparent"} stroke="#fff" strokeWidth="1.5" strokeLinecap="round"/>
                                </SvgIcon>
                            </IconButton>
                        </Box>
                        <CardHeader avatar={
                            <Avatar src={this.props.model.freelancer.avatar} sx={{bgcolor: "primary.main"}}/>
                        } title={this.props.model.freelancer.tag} subheader={this.formattedTimestamp()}/>
                        <Box sx={{
                            paddingLeft: "5px",
                            paddingRight: "5px"
                        }} component={Swiper} modules={[Navigation, Pagination]} pagination navigation
                             spaceBetween={10}>
                            {this.props.model.media.map((item, i) => {
                                let mediaType: React.ElementType
                                if (this.isVideo(this.props.model.media[i])) {
                                    mediaType = "video"
                                } else {
                                    mediaType = "img"
                                }
                                return (
                                    <SwiperSlide key={i}>
                                        <Box autoPlay controls muted
                                             src={item}
                                             component={mediaType}
                                             sx={{
                                                 objectFit: "contain",
                                                 height: {
                                                     xs: "150px",
                                                     sm: "300px",
                                                     md: "400px"
                                                 },
                                                 width: "100%",
                                             }}/>
                                    </SwiperSlide>
                                )
                            })}
                        </Box>
                        <CardContent>
                            <Typography variant={"h5"}>Description</Typography>
                            <Box mt={1} sx={{
                                maxHeight: "150px",
                                overflowY: "scroll",
                                backgroundColor: "rgba(255,255,255,0.1)",
                                border: "solid 2x transparent",
                                borderRadius: "6px",
                                paddingLeft: "10px",
                                paddingTop: "6px",
                                paddingRight: "10px",
                                paddingBottom: "6px"
                            }}>
                                <Typography variant={"body1"}>
                                    {this.description()}
                                </Typography>
                            </Box>
                            <Box mt={1} sx={{
                                textAlign: "right"
                            }}>
                                <Typography variant={"caption"}>
                                    ID: {this.props.model.id}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Slide>
        </Modal>
    }

    render() {
        let thisRef = this
        let mediaType: React.ElementType
        if (this.isVideo(this.props.model.media[0])) {
            mediaType = "video"
        } else {
            mediaType = "img"
        }
        return (
            <Card sx={{
                border: "3px solid transparent",
                cursor: "pointer",
                backgroundColor: "rgba(255,255,255,0.005)",
                "&:hover": {
                    transition: "500ms ease",
                    transform: "scale(103%)",
                    border: "3px solid #00A36C",
                },
            }}>
                <CardHeader avatar={
                    <Avatar src={this.props.model.freelancer.avatar} sx={{bgcolor: "primary.main"}}/>
                } title={
                    <Typography>
                        {this.props.model.freelancer.tag}
                    </Typography>
                } subheader={
                    <Typography sx={{
                        color: "#B3B3B3",
                    }}>
                        {this.formattedTimestamp()}
                    </Typography>
                }/>
                <CardMedia muted autoPlay component={mediaType} height={"200"} image={this.props.model.media[0]} sx={{
                    objectFit: "contain",
                    backgroundColor: "rgba(255,255,255,0.025)",
                    borderRadius: "12px",
                }}/>
                <CardContent>
                    <Typography sx={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "pre"
                    }} variant={"body1"}>
                        {this.props.model.name}
                    </Typography>
                </CardContent>
                <CardActions sx={{
                    paddingTop: 0
                }}>
                    <Button onClick={function () {
                        thisRef.setState({
                            detailsView: true
                        })
                    }} startIcon={
                        <SvgIcon width="800px" height="800px" viewBox="0 0 24 24" fill="none" sx={{
                            marginLeft: "auto",
                            marginRight: 0,
                            color: "primary.main"
                        }}>
                            <path
                                d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"/>
                            <path
                                d="M12 13C11.44 13 11 12.55 11 12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12C13 12.55 12.56 13 12 13Z"/>
                            <path
                                d="M16 13C15.44 13 15 12.55 15 12C15 11.45 15.45 11 16 11C16.55 11 17 11.45 17 12C17 12.55 16.56 13 16 13Z"/>
                            <path
                                d="M8 13C7.44 13 7 12.55 7 12C7 11.45 7.45 11 8 11C8.55 11 9 11.45 9 12C9 12.55 8.56 13 8 13Z"/>
                        </SvgIcon>
                    }>More</Button>
                </CardActions>
                {this.detailsModal()}
            </Card>
        );
    }
}
