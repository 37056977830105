import {Component, PropsWithChildren} from "react";
import {Container, Grow} from "@mui/material";

export class Page extends Component<PropsWithChildren, {}> {

    render() {
        return (
            <Grow in={true}>
                <Container sx={{
                    maxWidth: "1300px",
                    minHeight: "79vh"
                }}>
                    {this.props.children}
                </Container>
            </Grow>
        );
    }
}