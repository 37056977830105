import {Component} from "react";
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {PortfolioModel} from "../../../api/model/PortfolioModel";

export class Portfolio extends Component<{
    model: PortfolioModel
}> {
    render() {
        return (
            <Box to={this.props.model.name} component={Link} sx={{
                textDecoration: "none"
            }}>
                <Box sx={{
                    borderRadius: "8px",
                    border: "3px solid transparent",
                    cursor: "pointer",
                    padding: "8px 16px 8px 16px",
                    backgroundColor: "rgba(255,255,255,0.05)",
                    "&:hover": {
                        transition: "500ms ease",
                        transform: "scale(103%)",
                        border: "3px solid #00A36C"
                    }
                }}>
                    <Box sx={{
                        textDecoration: "none",
                        color: "white"
                    }}>
                        <Typography variant={"h6"}>{this.props.model.name}</Typography>
                        <Typography sx={{
                            color: "gray"
                        }}>
                            Over {this.props.model.projects_count.toString()} projects
                        </Typography>
                    </Box>
                    {/*
                    <Card sx={{
                        backgroundColor: "rgba(255,255,255,0.005)"
                    }}>
                        <CardContent>
                            <Typography gutterBottom variant={"h5"} component={"div"}>
                                {this.props.model.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Over {this.props.model.projects_count.toString()} projects
                            </Typography>
                        </CardContent>
                    </Card>
                    */}
                </Box>
            </Box>
        );
    }
}
